@font-face {
    font-family: 'raleway';
    src: url("/core/SB/fonts/raleway-bold-webfont.woff2") format('woff2'),
         url("/core/SB/fonts/raleway-bold-webfont.woff") format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'raleway';
    src: url("/core/SB/fonts/raleway-semibold-webfont.woff2") format('woff2'),
         url("/core/SB/fonts/raleway-semibold-webfont.woff") format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'raleway';
    src: url("/core/SB/fonts/raleway-regular-webfont.woff2") format('woff2'),
         url("/core/SB/fonts/raleway-regular-webfont.woff") format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
