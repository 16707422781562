@function sblib-checker-gradient($color-1: transparent, $color-2: $gray-200) {
    @return 
        linear-gradient(45deg, $color-2 25%, $color-1 25%, $color-1),
        linear-gradient(-45deg, $color-2 25%, $color-1 25%, $color-1),
        linear-gradient(45deg, $color-1 75%, $color-2 75%),
        linear-gradient(-45deg, $color-1 75%, $color-2 75%);
}

@mixin sblib-field {
    .sblib-thumb-icon {
        display: flex;
    }
    .sblib-thumb-filename {
        margin-left: $baseline / 2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .sblib-folder-name {
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon {
            width: $baseline * 3;
            height: $baseline * 3;
            color: $primary-color;
        }
        span {
            display: block;
            margin: 0;
            margin-top: $baseline;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &.sblib-type-image {
        .sblib-thumb-icon {
            justify-content: center;
            width: $baseline * 6;
            height: $baseline * 6;
            border: 2px solid $gray-200;
            img {
                background-image: sblib-checker-gradient();
                background-size: .5rem .5rem;
            }
        }
    }
    &.sblib-type-file {
        .sblib-thumb-icon {
            flex-basis: calc(100% - #{$baseline});
            max-width: calc(100% - #{$baseline});
            align-items: center;
        }
        .sblib-thumb-image {
            width: $baseline * 2;
            flex-shrink: 0;
        }
    }
}
@mixin sblib-checkbox($size, $border-width: (2/$em) * 1rem) {
    width: $size;
    height: $size;
    background-color: $white;
    border: $border-width solid $secondary-color;
    border-radius: (4/$em) * 1rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
@mixin sblib-checkbox-checked {
    @include sb-icon-check-invert($white);
    background-color: $secondary-color;
}

@mixin sblib-file-base {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: border-color 0.2s,
                transform .2s ease,
                opacity .2s,
                padding .1s;
    &::after {
        z-index: 1;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($anchor-color, .3);
        opacity: 0;
        pointer-events: none;
    }
    &.selected::after {
        opacity: 1;
    }
    &.dragging {
        opacity: .5;
    }
    .sblib-filename {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &::after {
            //safari hack to disable native tooltip
            content: '';
            display: block;
        }
    }
    .sblib-progress {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 4px;
        color: transparent;
    }
    .sblib-progress-indicator {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0;
        background-color: #61c9fa;
    }
}

@mixin sblib-file-image {
    @include sblib-file-base;
    border: 1px solid transparent;
    &:hover,
    &:focus {
        border-color: $anchor-color;
    }
    .sblib-icon {
        @include object-fit((object-fit: contain));
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        transition: opacity .2s;
        img {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: $off-white;
            background-image: sblib-checker-gradient();
            background-size: .5rem .5rem;
        }
        .sblib-hover-thumbnail {
            background-color: rgba(255,255,255,0.6);
            opacity: 0;
            transition: opacity .3s .3s ease;
        }
        &::after {
            @include sb-icon-move($dark-gray, $white, 1);
            content: '';
            display: block;
            position: absolute;
            width: 2rem;
            height: 2rem;
            right: 0;
            top: 0;
            background-size: (20/$em) * 1rem (20/$em) * 1rem;
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0;
            transition: opacity .3s;
        }
        &:hover {
            .sblib-hover-thumbnail {
                opacity: 1;
            }
            &::after {
                opacity: 1;
            }
        }
    }
    .sblib-filename {
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 0; 
        right: 0;
        padding: 0 $baseline / 2;
        line-height: $baseline * 2;
        background-color: rgba($black, .4);
        color: $text-color-white;
    }
    .sblib-progress {
        bottom: $baseline * 2;
    }
}

@mixin sblib-file-image-select {
    transform: scale(.95);
    &::before {
        z-index: 3;
        @include sblib-checkbox(2rem, (3/$em) * 1rem);
        content: '';
        display: block;
        position: absolute;
        top: $baseline / 2;
        left: $baseline / 2;
    }
    &.selected {
        transform: scale(.9);
        .sblib-icon {
            opacity: .3;
        }
        &::before {
            @include sblib-checkbox-checked;
        }
        &::after {
            opacity: 0;
        }
    }
}

@mixin sblib-file-compact {
    @include sblib-file-base;
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    padding: $baseline / 2 $baseline;
    background-size: (16/$em) * 1rem (16/$em) * 1rem;
    background-position: right $baseline center;
    background-repeat: no-repeat;
    &:hover,
    &:focus {
        @include sb-icon-move($dark-gray, $white, 1);
        background-color: rgba($secondary-color, .1);
    }
    .sblib-icon {
        width: 1.5em;
        height: 1.5em;
        margin-right: .5em;
        line-height: 0;
        flex-shrink: 0;
    }
}

@mixin sblib-file-compact-select {
    padding-left: $baseline * 3;
    &::before {
        @include sblib-checkbox((20/$em) * 1rem);
        content: '';
        display: block;
        position: absolute;
        left: $baseline;
    }
    &.selected {
        &::before {
            @include sblib-checkbox-checked;
        }
        &::after {
            opacity: 0;
        }
    }
}
