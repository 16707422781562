@function get-input-label-before-props($props: ()) {
    @return map-merge((
        label-margin: $baseline,
        error-font-size: (14/$em) * 1rem,
        error-line-height: 1.2,
        error-x-offset: 0.4rem, 
        error-padding: .2rem
    ), $props);
}

@mixin input-label-before-colors(
    $props: ()
) {
    $props: get-input-props($props, input-colors);
    .input-error {
        background-color: map-get($props, input-background-color);
    }
}
@mixin input-label-before-unset($props) {
    $props: get-input-props($props);

    $label-selector: map-get($props, label-selector);
    $border-width: get-border-value(map-get($props, input-border), width);

    display: block;

    #{$label-selector} {
        order: 0;
        max-width: none;
        margin: 0;
    }
    .input-error {
        @include form-message-size;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        padding: 0;
        border-radius: 0;
        background-color: transparent;
        max-width: none;
        font-size: initial;
        &::after {
            left: 0;
        }
    }
}
@mixin input-label-before($props: ()) {
    $props: get-input-props($props, input-label-hybrid);
    $label-selector: map-get($props, label-selector);
    $field-selector: map-get($props, field-selector);
    
    $input-border-radius: map-get($props, input-border-radius);
    $input-border-width: get-border-value(map-get($props, input-border), width);
    $label-margin: map-get($props, label-margin);

    $error-font-size: map-get($props, error-font-size);
    $error-x-offset: map-get($props, error-x-offset); 
    $error-padding: map-get($props, error-padding); 
    $error-line-height: map-get($props, error-line-height);
    @if unit($error-line-height) == '' {
        $error-line-height: $error-font-size * $error-line-height;
    }

    @include grid;
    position: relative;
    & > * {
        // order: 4;
    }
    #{$label-selector} {
        @include grid-row(1);
        @include grid-column(1);
        @include grid-align-self(center);
        margin-right: $label-margin;
    }
    #{$field-selector} {
        @include grid-row(1);
        @include grid-column(2);
    }
    .input-error {
        @include grid-row(2);
        @include grid-column(1, 2);
        @include grid-justify-self(end);
        @include form-message-size(
            $line-height: $error-line-height,
            $icon-size: $error-font-size,
            $icon-margin: $error-padding
        );
        z-index: 2;
        position: relative;
        right: $error-x-offset;
        margin-top: -#{$error-line-height / 2};
        margin-bottom: -#{$error-line-height / 2};
        padding-left: calc(#{$error-padding * 2} + #{$error-font-size});
        padding-right: $error-padding;
        max-width: calc(100% - #{$error-x-offset * 2});
        font-size: $error-font-size;
        overflow: hidden;
        border-radius: $input-border-radius;
        &::after {
            left: $error-padding;
        }
    }
}
