@function get-toolbar-height() {
    @return (
        small: calc(#{$baseline * 4} + 1px),
        large: calc(#{$baseline * 6.5} + 2px)
    );
}

@mixin sb-toolbar() {
    display: flex;
    position: relative;
    
    max-width: 100%;
    overflow-x: auto;
    flex-grow: 1;
    border-top: 1px solid $white;
    color: $white;
    background-color: $dark-gray;
    font-family: $body-font-family;
    font-weight: $normal; 
    font-size: 1rem;
    text-align: left;
    a {
        text-decoration: none;
        color: inherit;
    }
    a:hover,
    a:focus {
        color: $anchor-color-white-hover;
    }
    @include breakpoint(large up) {
        border: 1px solid $white;
        margin: 0 $baseline;
        flex-grow: 0;
    }

    .sb-toolbar-logo,
    .nav-list a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: $baseline * 4;
        min-width: $baseline * 4;
        padding: $baseline / 2;
        text-align: center;
        svg {
          width: auto;
          height: $baseline * 2;
          fill: currentColor;
        }
        span {
            display: none;
            margin: 0;
            margin-top: 0.5rem;
            font-size: (10/16) * 1rem;
            line-height: 1;
            text-transform: uppercase;
            margin-top: .5rem;
        }
        @include breakpoint(large up) {
            height: $baseline * 5.5;
            min-width: $baseline * 5;
            padding: $baseline $baseline $baseline * 3/4;
            justify-content: flex-end;
            span {
                display: block;
            }
        }
        .icon-toggle-on,
        .icon-toggle-off {
            //this icon is not square, so needs to be a little bigger
            //remove this when custom icon viewboxes are implemented
            height: $baseline * 3;
            margin: $baseline / -2;
        }
    }

    .sb-toolbar-logo {
        // background-color: $secondary-color;
        min-width: $baseline * 6;
        padding-left: $baseline;
        padding-right: $baseline;
        @include breakpoint(large up) {
            padding-left: $baseline * 1.5;
            padding-right: $baseline * 1.5;
            min-width: $baseline * 7;
        }
    }
    .sb-toolbar-logo-text {
        font-weight: $semibold;
    }
    .sb-logo-smallbox {
        background-color: $lime;
        color: $dark-gray;
    }
    .sb-logo-amira {
        background-color: $teal;
        color: $white;
    }

    .sb-toolbar-headings {
        border-left: 1px solid $white;
        padding: 0 $baseline / 2;
    }
    .nav-list {
        display: flex;
    }

    .sb-toolbar-details {
        display: none;
        flex-direction: column;
        justify-content: center;
        border-left: 1px solid $white;
        padding: $baseline * 3/4 $baseline * 1.5;
        background-color: $medium-gray;
        font-size: (10/$em) * 1rem;
        line-height: 1;
        @include breakpoint(large up) {
            display: flex;
        }
    }
    .sb-toolbar-detail {
        display: flex;
        margin-top: .4em;
    }
    .sb-toolbar-detail-title {
        margin-right: .5em;
    }

    .sb-admin-tools-sidebar & {
        margin: 0;
        flex-grow: 1;
        border: none;
        border-top: 3px solid $teal;
        .sb-toolbar-logo {
            display: none;
        }
        .sb-toolbar-headings {
            border-left: 0;
        }
        .nav-list {
            flex-wrap: wrap;
        }
    }
    .brand-smallbox .sb-admin-tools-sidebar & {
        border-top-color: $lime;
    }
}
