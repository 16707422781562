.sb-admin {
    @include form-base();
    @include input-all(
        $features: (
            '.input-set': input-fancy-fieldset,
            '.input fieldset': input-basic-fieldset,
            '.input-uploader-field': input-uploader
        ),
        $additional-styles: (
            '.switch': input-switch,
            '.checkbox-list li, .radio-buttons li, .input-type-checkbox, .input-type-radio': input-checkbox
        ),
    );
    .input-label,
    legend {
        font-weight: $semibold;
    }
    .label-after-inline,
    .input-type-checkbox,
    .input-type-radio,
    .checkbox-list li,
    .radio-buttons li {
        .input-label {
            font-weight: $normal;
        }
    }
    .label-after-inline {
        @include apply-input-mixin(input-checkbox);
        @include apply-input-mixin(input-checkbox-colors);
        margin-top: $baseline;
    }
    .input-set {
        margin-top: $baseline;
    }
    .input {
        & > .sb-button {
            align-self: flex-start;
        }
        .sb-button {
            margin-top: map-get(get-input-props(), label-margin);
        }
        .sb-input-group {
            &,
            .sb-button-group {
                margin-top: 0;
            }
        }
        #{text-inputs(
            password
            date
            datetime
            datetime-local
            number
            tel
            phone
            time
        )} {
            font-family: monospace, monospace;
        }
    }
    // .sb-button {
    //     @include apply-input-mixin(button-base, $button-props);
    //     @include button-color(base);       
    // }
    .input-group-button {
        display: flex;
    }

    //display inputs next to each other, can include helper button
    .sb-input-group,
    .input-group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: $baseline * -1;
        & > input,
        & > select,
        & > textarea {
            width: auto;
        }
        & > input,
        & > textarea {
            min-width: 0;
            flex-basis: 0px;
            flex-grow: 1;
        }
        // > *:first-child:not([type='hidden']) {
        //     border-top-right-radius: 0;
        //     border-bottom-right-radius: 0;
        // }
        // > *:last-child {
        //     border-top-left-radius: 0;
        //     border-bottom-left-radius: 0;
        // }
        // > *:not(:last-of-type):not(:first-of-type) {
        //     border-radius: 0;
        // }
        & > * {
            margin-top: map-get(get-input-props(), label-margin);
        }
        & > * {
            margin-left: $baseline;
        }
        .input-group-button {
          margin-left: $baseline / 2;
          & > * {
            margin-left: $baseline / 2;
          }
        }
    }
    //group fields that share a label/function (ex: date+time)
    .input-field-group {
        @include input-group($selector: '.input-field');
        margin-top: 0;
    }

    //legacy helper icon buttons
    .image-button {
        display: inline-block;
        overflow: hidden;
        text-indent: -999px;
        background-repeat:no-repeat;
        background-position: center;
        background-size: contain;
        width: 1.5rem;
        height: 1.5rem;
    }
    .image-lock,
    input:disabled ~ .input-group-button .sb-field-unlock,
    .locked-field.locked ~ .input-group-button .sb-field-unlock,
    input:disabled ~ .input-group-button .sb-permalink-unlock {
        @include sb-icon-lock($secondary-color);

        &:hover,
        &:focus {
            @include sb-icon-lock($anchor-color-hover);
        }
    }
    .image-help {
        @include sb-icon-help($secondary-color);

        &:hover,
        &:focus {
            @include sb-icon-help($anchor-color-hover);
        }
    }
    .sb-field-unlock,
    .sb-permalink-unlock {
        @include sb-icon-unlock($secondary-color);

        &:hover,
        &:focus {
            @include sb-icon-unlock($anchor-color-hover);
        }
    }
}

.sb-auto-columns {
    column-width: 36ch;
    column-gap: 24px;
    .input {
        display: inline-flex;
        width: 100%;
        break-inside: avoid;
    }
}

%button-space-base {
    margin-top: map-get(get-input-props($button-props), vertical-margin);
}
%button-space-base + %button-space-base {
    margin-left: $baseline;
    vertical-align: top;
}

.sb-admin .icon-button,
.sb-admin .text-icon-button,
.sb-button,
.sb-button-group,
.sb-dropdown-menu-container {
    @extend %button-space-base;
}
// .input label.sb-button,
.cke_dialog .sb-button, //cke editor includes reset styles that we must override
.sb-button,
a.sb-button,
.sb-admin .text-icon-button {
    @include sb-button;
}
.sb-button {
    //nudges icons.
    //lower specificity
    @include sb-button-icons;
}
.sb-button.sb-button-simple {
    @include sb-button-simple;
}
.sb-button.sb-button-hide-text {
    @include sb-button-hide-text;
}
.sb-button-simple.sb-button-color-alert {
    @include button-color(text-only-alert);
}

.sb-admin .icon-button {
    @include sb-icon(
        1.2rem,
        true,
        get-input-height($button-props, $calc: false)
        // get-side(map-get(get-input-props($button-props, button-base), button-padding), left) * 2
    );
}
.sb-admin .text-icon-button {
    padding-left: 1.75rem;
    padding-right: .125rem;
    background-repeat: no-repeat;
    background-size: 1.2rem 1.2rem;
    background-position: .125rem center;
    white-space: nowrap;
}

.sb-admin .text-icon-button {
    @include button-color(text-only);
}

.sb-dropdown-menu-container {
    %button-space-base {
        margin-top: 0;
    }
}

.sb-admin .drop-down-button {
    display: inline-flex;
    &::after {
        content: "";
        align-self: center;
        right: 0;
        margin-left: .5em;
        width: 1em;
        height: 1em;
        background: no-repeat center;
        background-size: contain;
        @include sb-icon-arrow($text-color-medium);
        transform: rotate(90deg);
    }
    &:hover::after,
    &:focus::after {
        @include sb-icon-arrow($anchor-color);
    }
    &.sb-button::after {
        @include sb-icon-arrow($white);
    }
    &.is-active::after {
        transform: rotate(-90deg);
    }
}

.sb-button-group {
    @include input-group;
    display: inline-flex;
    .sb-button {
        margin: 0 1px 0 0;
    }
    .sb-button + .sb-button {
        margin-left: 0;
    }
}
.sb-child-content .sb-icon {
    @include apply-input-mixin(button-base, map-merge($button-props, (
        button-padding: $baseline * 2 + (32/$em) * 1rem 1rem $baseline,    
    )));
    @include button-color(base);
    &:focus {
        outline: none;
    }
    // padding: calc(#{$baseline * 2} + 32px) 1em $baseline;
    background-size: (32/$em) * 1rem;
    background-position: center $baseline;
    min-width: 6rem;
    margin-right: $baseline;
    margin-top: $baseline;
    max-width: 10rem;
    white-space: normal;
}
