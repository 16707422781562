.sb-admin {
    .icon {
        display: inline-block;
        width: 1em;
        height: 1em;
        fill: currentColor;
        vertical-align: -.125em;
        //currently getting stuff from legacy icon-* classes
        background-image: none !important;
    }
    .icon + span,
    span + .icon {
        margin-left: .5em;
    }
}
