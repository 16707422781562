@import 'input-label-absolute';

@function get-input-label-hybrid-props($props: ()) {
    $abs-props: get-input-label-absolute-props($props);
    @return map-merge((
        label-margin: $baseline / 2,
        error-font-size: map-get($abs-props, label-font-size),
        error-line-height: 1.2,
        error-x-offset: map-get($abs-props, label-x-offset), 
        error-padding: map-get($abs-props, label-padding),
        error-background-bleed: map-get($abs-props, label-background-bleed)
    ), $props);
}

@mixin input-label-hybrid-colors(
    $props: ()
) {
    $props: get-input-props($props, input-label-hybrid input-colors);
    .input-error {
        background-color: map-get($props, input-background-color);
        box-shadow: 0 map-get($props, error-background-bleed) * -1 map-get($props, input-background-color),
                    0 map-get($props, error-background-bleed) map-get($props, input-background-color);
    }
}
@mixin input-label-hybrid-unset($props) {
    $props: get-input-props($props);
    $field-selector: map-get($props, field-selector);

    $border-width: get-border-value(map-get($props, input-border), width);

    display: block;
    flex-direction: row;

    #{$field-selector},
    .cke {
        margin-top: 0;
    }
    .input-error {
        @include input-label-absolute-floating-text-unset;
        @include form-message-size;
        z-index: 0;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        padding-right: 0;
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
        font-size: initial;
        &::after {
            left: 0;
        }
    }
}
@mixin input-label-hybrid($props: ()) {
    $props: get-input-props($props, input-label-hybrid);
    $label-selector: map-get($props, label-selector);
    $field-selector: map-get($props, field-selector);
    
    $input-border-radius: map-get($props, input-border-radius);
    $input-border-width: get-border-value(map-get($props, input-border), width);
    $label-margin: map-get($props, label-margin);

    $error-font-size: map-get($props, error-font-size);
    $error-x-offset: map-get($props, error-x-offset); 
    $error-padding: map-get($props, error-padding);
    $error-line-height: get-input-line-height($props, $type: error);

    position: relative;
    display: flex;
    flex-direction: column;
    #{$label-selector} {
        order: -1;
        max-width: 100%;
    }
    #{$field-selector},
    .cke {
        margin-top: $label-margin;
    }
    & > #{$field-selector},
    & > .cke {
        &:first-child {
            margin-top: 0;
        }
    }
    .button {
        align-self: flex-start;
    }
    .input-error {
        @include input-label-absolute-floating-text(
            $error-font-size,
            $error-line-height,
            $error-x-offset,
            $error-padding,
            $input-border-radius
        );
        @include form-message-size(
            $line-height: $error-line-height,
            $icon-size: $error-font-size,
            $icon-margin: $error-padding
        );
        z-index: 2;
        order: 3;
        align-self: flex-end;
        padding-left: calc(#{$error-padding * 2} + #{$error-font-size});
        &::after {
            left: $error-padding;
        }
    }
}
