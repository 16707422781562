@mixin sb-row($columns: 12) {
    display: grid;
    grid-template-columns: repeat($columns, 1fr);
    grid-column-gap: $gutter;
    grid-auto-flow: dense;
    & > * {
        @include sb-column($columns);
    }
    @each $breakpoint, $value in $breakpoints {
        @include breakpoint($breakpoint up) {
            @for $i from 1 through $columns {
                &.#{$breakpoint}-#{$i} {
                    grid-template-columns: repeat($i, 1fr);
                    & > * {
                        @include sb-column($i);
                    }
                }
            }
        }
    }
}

@mixin sb-column($column-span: 12) {
    grid-column-end: span $column-span;
}
