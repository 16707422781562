// -------------------------------------------------------
// Sizes
// -------------------------------------------------------
$em: 16;
$baseline: .75rem; //12px
$gutter: 12px;

$outer-gutters: (
  small: $gutter,
  medium: $gutter * 2,
);

$site-width: 100%;

//height of sticky header
// $header-height: (
//     small: $baseline * 5,
//     medium: $baseline * 6
// );
$breakpoints: (
  small: 0,
  smallplus: 400px,
  medium: 768px,
  large: 1020px,
  xlarge: 1400px,
  xxlarge: 1900px,
);
$breakpoint-classes: (small smallplus medium large xlarge xxlarge);

// -------------------------------------------------------
// Colors
// -------------------------------------------------------

$teal: #00A79D;
$teal-dark: #006a5d;
$teal-light: #7bccc5;
$teal-white: #bae5e2;

$lime: #CEC42D;
$lime-light: #e7e3a2;
$lime-dark: #9e940d;

$green: #8CC63E;
$green-dark: #5a881c;

$orange: #F16521;
$purple: #91268F;
$yellow: #FEC10D;
$red: #D50603;
$red-light: #ef8e77;
$blue: #0066AB;

$white: #fff;
$black: #000;

$gray-50: #f8f8f8;
$gray-100: #f0f0f0;
$gray-200: #e5e5e5;
$gray-300: #d4d4d4;
$gray-400: #b0b0b0;
$gray-500: #8f8f8f;
$gray-600: #686868;
$gray-700: #545454;
$gray-800: #363636;
$gray-900: #161616;

$off-white: $gray-50;
$light-gray: $gray-200;
$gray: $gray-400;
$medium-gray: $gray-700;
$dark-gray: $gray-800;

$primary-color: $teal;
$primary-color-light: $teal-light;
$secondary-color: $teal;

$success-color: $green;
$alert-color: $red;

$status-published: $green;
$status-pending: $orange;
$status-draft: $yellow;

$text-color-white: $white;
$text-color-light: $gray;
$text-color-medium: $gray;
$text-color-regular: $dark-gray;
$text-color-dark: $gray-900;

$anchor-color: $teal;
$anchor-color-hover: $teal-dark;

$anchor-color-white: $white;
$anchor-color-white-hover: $teal-white;

$window-background-color: $gray-100;
$content-background-color: $white;
$border-color: $gray-300;

$body-font-family: 'raleway', sans-serif;
$header-font-family: $body-font-family;
$normal: 400;
$semibold: 600;
$bold: 700;

$input-props: (
  label-font-size: (14/$em) * 1rem,
  label-line-height: 1.25rem,
  error-line-height: 1.2,
  label-margin: $baseline / 4,
  input-border-color: $gray-200,
  input-border-focus-color: $anchor-color,
  input-border-radius: (3/$em) * 1rem,
  input-line-height: (20/$em) * 1rem
);

$button-props: (
  button-padding: (9/$em) * 1rem 1rem,
  button-min-width: 0,
  input-font-size: (16/$em) * 1rem,
  input-line-height: (20/$em) * 1rem
);

$button-colors: (
  base: (
    background: $anchor-color,
    text: $text-color-white,
    border: $anchor-color,
    background-hover: $anchor-color-hover,
    text-hover: $text-color-white,
    border-hover: $anchor-color-hover,
  ),
  white: (
    background: $white,
    text: $anchor-color,
    border: $white,
    background-hover: $off-white,
    text-hover: $anchor-color-hover,
    border-hover: $off-white,
  ),
  text-only: (
    text: $anchor-color,
    background: transparent,
    border: transparent,
    text-hover: $anchor-color-hover,
    background-hover: transparent,
    border-hover: transparent,
  ),
  text-only-alert: (
    text: $alert-color,
    background: transparent,
    border: transparent,
    text-hover: darken($alert-color, 20%),
    background-hover: transparent,
    border-hover: transparent,
  ),
);

$typography: (
  header-base: (
    font-family: $header-font-family,
    font-weight: $semibold,
    // color: $text-color-regular,
    line-height: 1.2,
  ),
  text-base: (
    font-weight: $normal,
    line-height: 1.5,
    font-family: $body-font-family,
    // color: $text-color-regular,
  ),
  header-e: (
    base: header-base,
    small: (
      font-size: #{12/$em}rem,
      line-height: $baseline * 1.5,
    ),
    medium: (
      font-size: #{14/$em}rem,
    ),
  ),
  header-d: (
    base: header-base,
    small: (
      font-size: #{16/$em}rem,
      line-height: $baseline * 2,
    ),
    medium: (
      font-size: #{18/$em}rem,
    ),
  ),
  header-c: (
    base: header-base,
    small: (
      font-size: #{18/$em}rem,
    ),
    medium: (
      font-size: #{20/$em}rem,
    ),
  ),
  header-b: (
    base: header-base,
    small: (
      font-size: #{20/$em}rem,
    ),
    medium: (
      font-size: #{24/$em}rem,
    ),
  ),
  header-a: (
    base: header-base,
    small: (
      font-size: #{24/$em}rem,
    ),
    medium: (
      font-size: #{32/$em}rem,
    ),
  ),
  text-small: (
    base: text-base,
    small: (
      font-size: #{10/$em}rem,
    ),
    medium: (
      font-size: #{12/$em}rem,
    ),
  ),
  text-medium: (
    base: text-base,
    small: (
      font-size: #{14/$em}rem,
    ),
    medium: (
      font-size: #{16/$em}rem,
    ),
  ),
  text-large: (
    base: text-base,
    small: (
      font-size: #{16/$em}rem,
    ),
    medium: (
      font-size: #{18/$em}rem,
    ),
  ),
);

