.sb-section,
.sb-expander {
    border-radius: map-get(get-input-props(), input-border-radius);
    background-color: $white;
}

.sb-section {
    box-shadow: inset 0 0 0 1px $gray-300;
}

.sb-section-head,
.sb-expander-head {
    $button-padding: map-get(get-input-props($button-props, button-base), button-padding);
    // -moz-user-select: none;
    // -webkit-user-select: none;
    // user-select: none;  
    position: relative;
    padding: get-side($button-padding, top) $baseline get-side($button-padding, bottom);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius:  map-get(get-input-props(), input-border-radius);
    border-top-right-radius:  map-get(get-input-props(), input-border-radius);
    overflow: hidden;
    .text-icon-button {
        color: $text-color-white;
    }
    .sb-button {
        border-radius: 0;
        padding-top: calc(#{get-side($button-padding, top)} - 1px);
        padding-bottom: calc(#{get-side($button-padding, bottom)} - 1px);
        padding-left: calc(#{$baseline} - 1px);
        padding-right: calc(#{$baseline} - 1px);

        margin-top: calc(0px - #{get-side($button-padding, top)});
        margin-bottom: calc(0px - #{get-side($button-padding, bottom)});
        line-height: inherit;
        .icon:first-child {
            margin-left: 0;
        }
        .icon:last-child {
            margin-right: 0;
        }
        span {
            color: $text-color-white;
        }
        & + .sb-button {
            margin-left: 1px;
        }
    }
    .sb-buttons-left .sb-button:first-child,
    & > .sb-button:first-child {
        margin-left: calc(0px - #{$baseline});
    }
    .sb-buttons-right .sb-button:last-child,
    & > .sb-button:last-child {
        margin-right: calc(0px - #{$baseline});
    }
    
    .sb-button-simple {
        &:hover,
        &:focus {
            color: $anchor-color;
            span {
                color: $anchor-color;
            }
        }
    }
    .text-icon-button {
        margin-top: calc(1px - #{get-side($button-padding, top)});
        margin-bottom: calc(1px - #{get-side($button-padding, bottom)});
    }
}
.sb-section-head {
    background-color: $dark-gray;
    color: $text-color-white;
    box-shadow: inset 0 0 0 1px $gray-900;
}
.sb-section-body,
.sb-expander-body {
    padding: $baseline;
    padding-top: 0.1px;
    & > * {
        margin-top: $baseline;
    }
    & > %no-collapse {
        margin-top: 0;
    }
}
.sb-expander-body > *:first-child {
    margin-top: 0;
}
.sb-section {
    .sb-expander,
    .sb-admin-list-table {
        margin-top: -1px;
    }
}
.sb-section-body {
    .sb-expander {
        margin-left: -$baseline;
        margin-right: -$baseline;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .sb-expander:not(:last-child) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .sb-expander:last-child {
        margin-bottom: $baseline * -1;
    }
}

.sb-expander {
    border: 1px solid $gray-300;
    transition: background-color .3s;
}
.sb-expander:not(.sb-expander-open) {
    background-color: $off-white;
}
.sb-expander-head {
    cursor: pointer;
    color: $text-color-regular;
    &::after {
        @include sb-icon-plus($white);
        content: '';
        display: block;
        width: (20/$em) * 1rem;
        height: (20/$em) * 1rem;
        border-radius: 50%;
        background-size: .75rem .75rem;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $gray;
        transition: background-color .2s;
        margin-left: $baseline;
    }
    &:hover::after {
        background-color: $dark-gray;
    }
    [type="radio"] {
        margin-right: .5em;
    }
    span + * {
        margin-left: $baseline;
    }
}
.sb-expander-title {
    flex-grow: 1;
}
.sb-expander-body {
    display: none;
}

.sb-expander-open {
    .sb-expander-head::after {
        @include sb-icon-minus($white);
    }
    .sb-expander-body {
        display: block;
        opacity: 1;
        transition: opacity .2s .4s;
    }
}

.sb-buttons-left,
.sb-buttons-center,
.sb-buttons-right {
    display: flex;
    flex-wrap: wrap;
}
.sb-buttons-left {
    margin-right: auto;
}
.sb-buttons-center {
    margin-left: auto;
    margin-right: auto;
}
.sb-buttons-right {
    margin-left: auto;
}

.sb-admin-breadcrumbs {
    @include breadcrumbs;
    @include typography(text-large);
    line-height: 1;
}

.sb-admin-notifications {
    z-index: 20;
    position: sticky;
    top: 0;
    .sb-message {
        @include form-message($line-height: 1.5em, $icon-margin: .8em, $icon-color: $secondary-color);
        position: absolute;
        top: $baseline;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;
        border-radius: 1.5rem;
        padding-right: .75em;
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        color: $secondary-color;
        overflow: hidden;
        min-height: 0;
        &::after {
            left: .4em;
        }
    }
    .sb-error-message {
        @include breakout($outer-gutters) {
            padding: $baseline / 2 $value;
        }
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: $text-color-white;
        background-color: $alert-color;
        font-weight: $semibold;
        .sb-button {
            @include button-color(white);
            color: $alert-color;
            margin: 0;
            margin-left: auto;
            &:hover,
            &:focus {
                color: $alert-color;
            }
        }
        .sb-error-message-detail {
            font-weight: $normal;
            flex-basis: 100%;
        }
        &:empty {
            display: none;
        }
    }
}
.sb-admin-status {
    display: inline-flex;
    align-items: baseline;
    &::before {
        content: '';
        display: block;
        flex-shrink: 0;
        margin-right: .5em;
        width: 1em;
        height: 1em;
        align-self: center;
        border-radius: 50%;
    }
    &.published::before {
        background-color: $status-published;
    }
    &.pending::before {
        background-color: $status-pending;
    }
    &.draft::before {
        background-color: $status-draft;
    }
}
.sb-admin-tabs {
    margin-top: $baseline;
    .tabs {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .tabs-title > * {
        display: block;
        cursor: pointer;
        padding: $baseline / 2 $baseline;
        margin-right: 1px;
        background-color: $dark-gray;
        color: $white;
        opacity: .6;
        &:hover,
        &:focus {
            opacity: .9;
        }
    }
    .tabs-title.is-active > * {
        opacity: 1;
        cursor: auto;
    }
}

.sb-admin .avatar {
    @include avatar((
        small: $baseline * 3.5,
    ));
}

.sb-dropdown-menu-container {
    display: inline-block;
    position: relative;
    .sb-dropdown-menu {
        z-index: 10;
        position: absolute;
        top: 100%;
        left: 0;
        display: block;
        min-width: 100%;
        visibility: hidden;
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top center;
        transition: visibility 0s .6s,
                    opacity .3s .3s ease,
                    transform .3s .3s ease;
    }
    .sb-dropdown-menu-right {
        left: auto;
        right: 0;
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: $secondary-color;
        transform: scaleX(0);
        transition: transform .3s ease;
    }
}
.sb-dropdown-menu-container:hover::after {
    transform: none;
}
.sb-dropdown-menu-container:hover .sb-dropdown-menu,
.sb-dropdown-menu:hover {
    visibility: visible;
    opacity: 1;
    transform: none;

    transition: visibility 0s .3s,
                opacity .3s .3s ease,
                transform .3s .3s ease;
}

.sb-dropdown-menu-right {
    @extend .sb-dropdown-menu;
}

.edit-checkbox-list.category-list {
    .category-drag {
        width: 1em;
        height: 1em;
        border: 0;
    }
    .category-title {
        display: flex;
        flex-grow: 1;
    }
}

.sb-child-content .sb-section-body {
    display: flex;
    flex-wrap: wrap;
}
.sb-button.child-content-item {
    .icon {
        width: $baseline * 3.5;
        height: $baseline * 3.5;
        margin: $baseline / 2;
    }
}
