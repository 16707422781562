@mixin sb-button {
    @include apply-input-mixin(button-base, $button-props);
    @include button-color(base);
    &:focus {
        outline: none;
    }
}

@mixin sb-button-icons {
    .icon:first-child {
        margin-left: -.25rem;
    }
    .icon:last-child {
        margin-right: -.25rem;
    }
}

@mixin sb-button-simple {
    @include button-color(text-only);
    padding-left: 0;
    padding-right: 0;
    .icon:first-child {
        margin-left: 0;
    }
    .icon:last-child {
        margin-right: 0;
    }
}

@mixin sb-button-hide-text {
    span {
        visibility: hidden;
        width: 0;
        height: 0;
        position: absolute;
        pointer-events: none;
    }
    .icon {
        margin: 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: get-input-height($button-props);
    height: get-input-height($button-props);
    // width: (30/$em) * 1rem;
    // height: (30/$em) * 1rem;
    // border-radius: 50%;
    padding: 0;
}
