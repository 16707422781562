@import 'admin:variables';
@import 'admin:icon-mixins';

//mixins
@import '@core/utility';
@import '@core/breakpoint';

@import 'admin:grid-mixins';
// @import '@core/navigation-mixins';
// @import '@core/typography';
@import '@core/forms';

@import 'mixins';


/*
Layout
*/

.sblib-cont {
    display: flex;
    flex-direction: column;
    //this has a foundation-based screen reader class that might be worth relying on
    //instead of this
    .sblib-browse{
        .sblib-browse-input {
            @include sr-only();
        }
    }
}
.sblib-head {
    width: 100%;
}

.sb-admin-tools-body .sblib-cont,
.dialog .sblib-cont {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: $baseline * 2;
}

.sblib-title-cont,
.sblib-button-cont,
.sblib-body {
    @include breakout($outer-gutters) {
        padding-left: $value;
        padding-right: $value;
    }
}

.sblib-title-cont {
    padding-top: $baseline;
    padding-bottom: $baseline;
    background-color: $dark-gray;
    color: $text-color-white;
}
.sblib-button-cont {
    padding-top: $baseline;
    padding-bottom: $baseline;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sb-button {
        margin-top: 0;
    }
}

.sblib-body {
    @include sb-row;
    grid-template-rows: 100%;
    flex-grow: 1;
    min-height: 0; /* firefox doesn't constrain to the body without this */
    .sb-section {
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .sb-section-head {
        flex-shrink: 0;
        .sblib-browse,
        .sblib-editor-choose {
            @include breakpoint(large up) {
                min-width: $baseline * 10;
            }
        }
    }
    .sb-section-body {
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
    }
    .sb-section-body.sb-list {
        padding-left: 1px;
    }
    .sblib-secondary {
        @include sb-column(4);
    }
    .sblib-primary {
        @include sb-column(8);
    }
    @include breakpoint(xlarge up) {
        .sblib-secondary {
            @include sb-column(3);
        }
        .sblib-primary {
            @include sb-column(9);
        }
    }
}

/*
List
*/
.sblib-dnd-upload {
    display: flex;
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
    color: $text-color-light;
}

.sblib-files.sb-section-body {
    padding-top: $baseline;
    & > * {
        margin: 0;
    }
}

.sb-section-body.sblib-files-loading {
    @keyframes sblib-spinner-fadein {
        from {
            opacity: 0;
        }
    }
    display: flex;
    .spinner {
        margin: auto;
        width: $baseline * 10;
        height: $baseline * 10;
        animation: sblib-spinner-fadein .4s both;
    }
}

.sblib-mode-image {
    .sblib-files {
        display: grid;
        grid-template-columns: repeat( auto-fill, minmax(#{$baseline * 15}, 1fr) );
        grid-auto-rows: min-content;
    }
    .sblib-file {
        @include sblib-file-image;
    }

    .sblib-mode-select .sblib-file {
        @include sblib-file-image-select;
    }
}

.sblib-mode-file {
    .sblib-files {
        padding: $baseline / 2 0;
    }
    .sblib-file {
        @include sblib-file-compact;
    }
    
    .sblib-mode-select .sblib-file {
        @include sblib-file-compact-select;
    }
}

/*
Buttons
*/
.sblib-home {
    display: flex;
    cursor: pointer;
    padding: $baseline / 2 0;
    padding-left: 1.5rem;
    font-size: 1rem;
    line-height: (18/$em) * 1rem;
    &.is-active {
        color: $primary-color;
    }
    .icon + span {
        margin-left: .25em;
    }
    &:hover,
    &:focus {
        color: $anchor-color;
    }
}
.sblib-select-all-button,
.sblib-deselect-all-button {
    display: inline-flex;
    align-items: center;
    &::before {
        @include sblib-checkbox((18/$em) * 1rem);
        content: '';
        display: block;
        flex-shrink: 0;
        margin-right: .5em;
        background-color: transparent;
        transform: translateY((-1/$em) * 1em);
    }
}
.sblib-deselect-all-button::before {
    @include sblib-checkbox-checked;
}


/*
Editor
*/
.sblib-editor {
    .sblib-editor-body {    
        flex-grow: 1;
        padding: 0 $baseline;
        margin: 0;
        padding-top: $baseline;
        height: 100%;
        overflow: visible;
        position: relative;
        min-height: 0;
        @include breakpoint(medium down) {
            overflow-y: auto;
        }
    }
    .column {
        padding-bottom: $baseline;
        .sb-section-body {
            padding-bottom: 0;
        }
    }
}

.sblib-editor-col1 {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    grid-gap: $baseline;
    align-items: start;
    justify-items: start;
}
.sblib-editor-preview {
    grid-row: 2;
    position: relative;
    align-self: stretch;
    justify-self: stretch;
}
.sblib-square-tool-button {
    margin-top: 0;
}
.sblib-editor-image {
    width: 100%;
    max-height: $baseline * 20;
    object-fit: contain;
    background-image: sblib-checker-gradient();
    background-size: .5rem .5rem;
    &[src$=".svg"] {
        width: $baseline * 20;
        height: $baseline * 20;
    }
    @include breakpoint(large up) {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
}
.sblib-editor-col2 {
    display: flex;
    flex-direction: column;
    @include breakpoint(large up) {
        max-height: 100%;
        align-self: start;
    }
}

.sblib-edit-image {
    height: auto;
    position: fixed;
    top: 45px;
    bottom: 15px;
    left: 45px;
    right: 15px;
}


/*
Crop tool
*/
.sblib-square-tool-cont {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}
.sblib-square-tool {
    position: absolute;
    border: solid 1px $black;
    top: 0;
    left: 0;
    cursor: move;
    box-shadow: 0px 0px 0px 10000px rgba(255, 255, 255, .5);
}
.sblib-square-tool-horizontal {
    height: 100%;
}
.sblib-square-tool-vertical {
    width: 100%;
}
.sblib-square-handle {
    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100vw;
        height: 1px;
        background-color: $white;
        box-shadow: 0 1px 0 rgba(0,0,0,0.5);
        transform: translateX(-50%);
    }
    &::after {
        transform: translateX(-50%) rotate(270deg);
    }
}

/*
image thumbnail field
*/
.sblib-field {
    @include sblib-field;
}

/*
rte
*/



