.sb-admin {
    .row {
        //prevents interior margin collapsing
        @extend %no-collapse !optional;
        @include sb-row;
    }
    @each $breakpoint, $value in $breakpoints {
        @include breakpoint($breakpoint up) {
            @for $i from 1 through 12 {
                .#{$breakpoint}-#{$i} {
                    @include sb-column($i);
                }
            }
        }
    }
}
