.sb-quick-search {
    position: relative;
    // justify-self: start;
    width: calc(100% - #{$gutter});
    max-width: 50ch;
    margin-right: $gutter;
}
.sb-quick-search-box {
    @include apply-input-mixin(input-base input-colors, (
        vertical-margin: 0,
        field-selector: '.sb-quick-search-input',
        input-border-color: $border-color
    ));
    display: grid;
    grid-template-columns: 1fr auto auto;
    & > * {
        grid-row: 1;
    }
    .sb-quick-search-input {
        padding-right: get-input-height($calc: false) * 2;
    }
}
.sb-quick-search-input {
    grid-column: 1 / span 3;
}
.sb-quick-search-reset,
.sb-quick-search-button {
    z-index: 2;
    position: relative;
}
.sb-quick-search-reset {
    grid-column: 2;
}
.sb-quick-search-button {
    grid-column: 3;
    display: flex;
    width: get-input-height();
    .sb-quick-search-submit {
        width: 100%;
        height: auto;
        background-size: (20/$em) * 1rem (20/$em) * 1rem;
        flex-shrink: 0;
    }
    &::before {
        content: '';
        width: 1px;
        margin-top: 3px;
        margin-bottom: 3px;
        flex-shrink: 0;
        background-color: $light-gray;
    }
}
.sb-quick-search-submit {
    @include sb-icon-search($text-color-light);
}
// .sb-quick-search-input:focus ~ .sb-quick-search-button,
.has-value ~ .sb-quick-search-button {
    .sb-quick-search-submit {
        @include sb-icon-search($anchor-color);
        &:hover,
        &:focus {
            @include sb-icon-search($anchor-color-hover);
        }
    }
}
.sb-quick-search-reset {
    box-sizing: content-box;
    align-self: center;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    width: 1rem;
    height: 1rem;
    padding: .5rem;
    transition: opacity .2s;
    @include sb-icon-x($text-color-light);
    background-size: 1rem 1rem;
    background-position: center;
    background-repeat: no-repeat;
    &:hover,
    &:focus {
        @include sb-icon-x($anchor-color);
    }
}
.has-value ~ .sb-quick-search-reset {
    opacity: 1;
    pointer-events: auto;
}

.sb-advanced-search-form {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
}
.sb-saved-searches {
    padding-right: .75rem;
    margin-right: .75rem;
    position: relative;
    // border-right: 1px solid $text-color-light;
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: $baseline / 2;
        bottom: $baseline / 2;
        right: 0;
        width: 1px;
        background-color: $text-color-light;
    }
    &:empty {
        display: none;
    }
    .sb-dropdown-menu-container {
        margin-top: 0;
    }
    .sb-context-menu {
        li {
            display: flex;
            align-items: center;
        }
        .sb-saved-search-link {
            flex-grow: 1;
            flex-shrink: 0;
        }
        .sb-delete-saved-search {
            @include sb-icon-close($text-color-light);
            flex-shrink: 0;
            width: 1em;
            height: 1em;
            padding: 0;
            margin: 0;
            margin-left: .5em;
            text-indent: 100%;
            overflow: hidden;
            &:hover,
            &:focus {
                @include sb-icon-close($text-color-regular);
            }
        }
    }
}
.sb-saved-searches-button {
    font-weight: $semibold;
    padding: $baseline / 2 0;
}
.sb-advanced-search-toggle {
    font-weight: $semibold;
    display: flex;
    align-items: center;
    &::after {
        @include sb-icon-plus($white);
        content: '';
        display: block;
        width: (20/$em) * 1rem;
        height: (20/$em) * 1rem;
        margin-left: .75em;
        border-radius: 50%;
        background-size: .75rem .75rem;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $gray;
        transition: background-color .2s;
    }
    &:hover::after {
        background-color: $dark-gray;
    }
}
.sb-advanced-search-toggle-button-open::after {
    @include sb-icon-minus($white);
}


/*
Advanced search fields
*/
.sb-advanced-search-fields {
    justify-self: end;
    width: 100%;
    grid-column-end: span 3;
    margin-top: $baseline;
    padding: 1rem;
    padding-top: 1rem - $baseline;
    background-color: $off-white;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        background-color: $off-white;
        width: 20px;
        height: 20px;
        top: -10px;
        right: 48px;
        transform: rotate(45deg);
    }
    #{text-inputs()} {
        width: auto;
        min-width: 15rem;
    }
    @include breakpoint(medium up) {
        .input {
            @include apply-input-mixin(input-label-hybrid-reset input-label-before, (
                label-margin: $baseline,
                label-font-size: 1rem,
            ));
        }
    }
}
@include breakpoint(medium up) {
    .sb-advanced-search-add-field .input-label {
        min-width: 9rem;
    }
    .sb-advanced-search-title .input-label {
        min-width: 9rem - $baseline * 3;
    }
}

.sb-advanced-search-add-field,
.sb-advanced-search-list-item {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    & > * {
        margin-left: $baseline * 3;
    }
    & > *:first-child,
    .sb-advanced-search-trash {
        margin-left: 0;
    }
    .sb-advanced-search-trash {
        align-self: center;
    }
    .sb-advanced-search-trash + * {
        margin-left: $baseline;
    }
}
.sb-advanced-search-sub-field {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    & > * {
        margin-right: $baseline;
    }
}
.sb-advanced-search-list-item {
    margin-top: $baseline;
    border-top: 1px solid $light-gray;
}
.sb-advanced-search-save-container,
.sb-advanced-search-save-dialog {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.sb-advanced-search-save-container {
    margin-top: $baseline;
}
.sb-advanced-search-main-buttons {
    margin-right: 2rem;
}
.sb-advanced-search-save-dialog {
    & > * {
        margin-right: 1rem;
    }
}

.sb-advanced-search-trash {
    @include sb-icon-delete($text-color-light);
    cursor: pointer;
    display: block;
    width: $baseline * 2;
    height: $baseline * 2;
    margin-top: $baseline;
    background-position: center;
    background-repeat: no-repeat;
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
    &:hover,
    &:focus {
        @include sb-icon-delete($alert-color);
    }
}


//sayt
.sb-admin {
  .sayt {
    position: relative;
  }
  .sayt-results ul {
    @include unstyled-list;
  }
  .sayt-cont {
    z-index: 20;
    background-color: $white;
    box-sizing: border-box;
    width: 100%;
    left: 0;
    top: 100%;
    margin: 0;
    box-shadow: 0 4px 24px -4px rgba(0,0,0,0.15),
                0 1px 2px 0 rgba(0,0,0,0.3);
  }
  .sayt-cont::after {
    display: none;
  }
  .sayt-results {
    li {    
      display: block;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
    .sayt-no-link,
    .search-result > * {
      display: block;
      padding: $baseline / 2 $baseline;
      background-color: transparent;
      &:hover {
        color: inherit;
        background-color: $gray-100;
      }
    }
    .sayt-selected,
    .search-result.sayt-selected,
    .sayt-selected > * {
        color: inherit;
        background-color: $gray-100;
    }
    .entity-search-result {
        .entity-title {
            font-weight: $semibold;
        }
        .entity-locator,
        .entity-path {
            font-size: .75rem;
        }
    }
  }

  .sayt-results h3 {
      margin: 0;
      font-size: .75rem;
  }

  .sayt-results li:hover h3 {
      color: white;
  }

  .sayt-no-results {
      display: block;
      text-align: center;
  }
}
