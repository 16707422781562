@import '@core/icon-mixins';
@mixin sb-icon($icon-size: 16px, $hide-text: false, $button-size: $icon-size * 1.5) {
    background-repeat: no-repeat;
    background-size: $icon-size $icon-size;
    white-space: nowrap;
    @if not $hide-text {
        background-position: #{($button-size - $icon-size) / 2} center;
        padding-left: $button-size;
        // padding-right: #{($button-size - $icon-size) / 2};
    } @else {
        padding: 0;
        background-position: center;
        text-indent: 100%;
        color: transparent;
        overflow: hidden;
        width: $button-size;
        height: $button-size;
    }
}
@mixin sb-icon-refresh($svg-color: $primary-color){
    background-image: inline-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 286.052 286.052"><path style="fill:#{$svg-color};" d="M78.493,143.181H62.832v-0.125c0-43.623,34.809-80.328,79.201-80.122c21.642,0.098,41.523,8.841,55.691,23.135l25.843-24.931c-20.864-21.043-49.693-34.049-81.534-34.049c-63.629,0-115.208,51.955-115.298,116.075h-15.84c-9.708,0-13.677,6.49-8.823,14.437l33.799,33.504c6.704,6.704,10.736,6.91,17.646,0l33.799-33.504C92.17,149.662,88.192,143.181,78.493,143.181z M283.978,129.236l-33.799-33.433c-6.892-6.892-11.156-6.481-17.637,0l-33.799,33.433c-4.854,7.929-0.894,14.419,8.814,14.419h15.635c-0.25,43.337-34.943,79.72-79.183,79.514c-21.633-0.089-41.505-8.814-55.691-23.099l-25.843,24.896c20.873,21.007,49.702,33.996,81.534,33.996c63.432,0,114.869-51.579,115.28-115.298h15.867C284.872,143.655,288.832,137.156,283.978,129.236z"/></svg>');
}
@mixin sb-icon-cancel($svg-color: $primary-color) {
    background-image: inline-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 65.518 65.518" style="fill: #{$svg-color}"><path d="M32.759,0C14.696,0,0,14.695,0,32.759s14.695,32.759,32.759,32.759s32.759-14.695,32.759-32.759S50.822,0,32.759,0z M6,32.759C6,18.004,18.004,6,32.759,6c6.648,0,12.734,2.443,17.419,6.472L12.472,50.178C8.443,45.493,6,39.407,6,32.759z M32.759,59.518c-5.948,0-11.447-1.953-15.895-5.248l37.405-37.405c3.295,4.448,5.248,9.947,5.248,15.895C59.518,47.514,47.514,59.518,32.759,59.518z"/></svg>');
}
@mixin sb-icon-cut($svg-color: $primary-color) {
    background-image: inline-svg('<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="fill: #{$svg-color}"><path d="M512,107.275c-23.658-33.787-70.696-42.691-104.489-19.033L233.753,209.907l-63.183-44.246c23.526-40.618,12.46-93.179-26.71-120.603c-41.364-28.954-98.355-18.906-127.321,22.45c-28.953,41.358-18.913,98.361,22.452,127.327c28.384,19.874,64.137,21.364,93.129,6.982l77.388,54.185l-77.381,54.179c-28.992-14.375-64.743-12.885-93.129,6.982c-41.363,28.966-51.404,85.963-22.452,127.32c28.966,41.363,85.963,51.411,127.32,22.457c39.165-27.424,50.229-79.985,26.71-120.603l63.183-44.246l173.751,121.658c33.793,23.665,80.831,14.755,104.489-19.033l-212.41-148.715L512,107.275z M91.627,167.539c-26.173,0-47.392-21.219-47.392-47.392s21.22-47.392,47.392-47.392c26.179,0,47.392,21.219,47.392,47.392S117.806,167.539,91.627,167.539z M91.627,439.253c-26.173,0-47.392-21.219-47.392-47.392c0-26.173,21.219-47.392,47.392-47.392c26.179,0,47.392,21.219,47.392,47.392C139.019,418.033,117.806,439.253,91.627,439.253z"/></svg>');
}
@mixin sb-icon-paste($svg-color: $primary-color) {
    background-image: inline-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 561 561" style="fill: #{$svg-color}"><path d="M459,51H351.9c-10.2-30.6-38.25-51-71.4-51c-33.15,0-61.2,20.4-71.4,51H102c-28.05,0-51,22.95-51,51v408c0,28.05,22.95,51,51,51h357c28.05,0,51-22.95,51-51V102C510,73.95,487.05,51,459,51z M280.5,51c15.3,0,25.5,10.2,25.5,25.5S295.8,102,280.5,102S255,91.8,255,76.5S265.2,51,280.5,51z M459,510H102V102h51v76.5h255V102h51V510z"/></svg>');
}
@mixin sb-icon-select($svg-color: $primary-color) {
    background-image: inline-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" style="fill: #{$svg-color}"><path d="M368.64,0H20.48C9.175,0,0,9.175,0,20.48v348.16c0,11.305,9.175,20.48,20.48,20.48h348.16c11.305,0,20.48-9.175,20.48-20.48V20.48C389.12,9.175,379.945,0,368.64,0z M348.16,348.16H40.96V40.96h307.2V348.16z"/><path d="M471.04,419.84v51.2h-51.2V512h71.68c11.305,0,20.48-9.175,20.48-20.48v-71.68H471.04z"/><rect x="262.349" y="471.04" width="110.182" height="40.96"/><path d="M163.84,471.04v-51.2h-40.96v71.68c0,11.305,9.175,20.48,20.48,20.48h71.68v-40.96H163.84z"/><path d="M491.52,122.88h-71.68v40.96h51.2v51.2H512v-71.68C512,132.055,502.825,122.88,491.52,122.88z"/><rect x="471.04" y="262.349" width="40.96" height="110.182"/></svg>');
}
@mixin sb-icon-crop($svg-color: $primary-color) {
    background-image: inline-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 956.815 956.815" style="fill: #{$svg-color};"><path d="M137.621,162.622H20c-11.046,0-20,8.954-20,20v72.919c0,11.046,8.954,20,20,20h117.622L137.621,162.622L137.621,162.622z"/><path d="M774.193,956.815c11.046,0,20-8.954,20-20V819.193H681.274v117.621c0,11.046,8.954,20,20,20L774.193,956.815L774.193,956.815z"/><path d="M794.193,656.275V182.622c0-11.046-8.954-20-20-20H300.54v112.919h380.734v380.734H794.193z"/><path d="M936.814,681.275H794.193H681.274H275.54V275.541V162.622V20c0-11.046-8.954-20-20-20h-72.918c-11.046,0-20,8.954-20,20v142.622v112.919v498.653c0,11.046,8.954,20,20,20h498.653h112.918h142.622c11.045,0,20-8.954,20-20v-72.918C956.814,690.229,947.86,681.275,936.814,681.275z"/></svg>');
}
