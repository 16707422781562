//list containers
    .sb-list-base,
    .sb-list-children {
        @include unstyled-list();
    }

    .sb-list-section .sb-section-body {
        padding-left: 0;
    }

    .sb-list-panel {
        padding-top: $baseline;
        margin-top: 0;
        @include breakpoint(medium up) {
            padding-right: $baseline;
        }
        .sb-title {
            padding: 0 $baseline / 2;
            font-weight: $bold;
            font-size: (12/$em) * 1rem;
        }
        &:last-of-type {
            margin-bottom: 2 * $baseline;
        }
        // &:first-of-type {
        //     padding-top: 0;
        // }
    }
    .sb-list-panel-header {
        display: flex;
        margin-top: $baseline;
    }
    .sb-list-base {
        font-size: 1rem;
        line-height: $baseline * 1.5;
    }
    .sb-list-children {
        position: relative;
        padding-left: $baseline * 1.5;
        &:empty {
            display: none;
        }
    }
    .collapsed > .sb-list-children {
        display: none;
    }


//page list item
    .sb-list-item {
        position: relative;
        padding-top: .1px;
    }
    .sb-list-row {
        margin-top: $baseline;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        height: $baseline * 2;
        padding-left: $baseline * 3;
    }

    @include breakpoint(medium down) {
        .sb-list-link {
            order: 1;
        }
        .sb-list-field {
            order: 2;
        }
        .sb-status-cont {
            order: 3;
        }
        .sb-list-menu {
            order: 4;
        }
        .sb-list-row::after {
            order: 5;
        }
    }

    //top level page list items
    .sb-list-panel .sb-title,
    .sb-list-panel-footer .sb-button {
        margin-left: $baseline * 2;
    }

    @mixin sb-node-list-label {
        z-index: 1;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: $baseline / 2 0;
        transform: translateY($baseline / 2);
        max-width: 100%;
        cursor: pointer;
        flex-basis: 0%;
        width: 0;
        flex-grow: 2;
        &::after {
            content: '';
            display: block;
            position: absolute;
            height: 1px;
            width: 100%;
            background-color: $anchor-color;
            opacity: 0;
            transition: opacity .2s;
        }
        &:focus::after {
            opacity: 1;
        }
    }

    .sb-list-link,
    .sb-list-field {
        @include sb-node-list-label;
    }
    .sb-list-link {
        flex-grow: 1;
        // flex-basis: 0px;
        // width: 0;
        color: $text-color-regular;
    }
    .sb-list-field {
        // flex-basis: 50vw;
        @include breakpoint(smallplus down) {
            display: none;
        }
    }
    .sb-list-field-header {
        font-weight: $bold;
        font-size: (12/$em) * 1rem;
    }
    .sb-list-path {
        flex-basis: 30vw;
        max-width: 640px;
        flex-grow: 0;
        color: $text-color-light;
    }
    .sb-list-link,
    .sb-list-path {
        &:focus {
            outline: 0;
            color: $anchor-color;
        }
    }
    .sb-status-cont {
        min-width: calc(3.25em + 8ch);
        text-align: right;
        .icon ~ span {
            margin-left: .25em;
        }
        .sb-admin-status {
            text-align: left;
            &::before {
                width: .75em;
                height: .75em;
                margin-right: .25em;
            }
            span {
                min-width: 8ch;
            }
        }
        @include breakpoint(smallplus down) {
            min-width: 3em;
            .sb-admin-status {
                &::before {
                    margin: 0;
                }
                span {
                    display: none;
                }
            }
        }
    }

//page list menu
    @mixin sb-node-list-menu-compact {
        opacity: 1;
        opacity: 1;
        width: $baseline * 2;
        height: $baseline * 2;
        padding: 0;
        .sb-list-menu-expand {
            display: flex;
        }
    }
    @mixin sb-node-list-menu-button-compact {
        height: $baseline * 2.5;
        width: $baseline * 2.5;
        padding: 0;
        line-height: $baseline * 2.5;
        margin-top: 0;
        margin-right: $baseline / 2;
        border-radius: 50%;
        text-align: center;
        background-color: $gray-100;
        span {
            display: none;
        }
    }
    .sb-list-menu {
        z-index: 2;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        transition: opacity .25s,
                    background-color .2s;
        .sb-list-menu-expand {
            display: none;
        }
    }
    .sb-list-menu-expand {
        justify-content: center;
        align-items: center;
        width: $baseline * 2;
        height: $baseline * 2;
        flex-shrink: 0;
        border-radius: 50%;
        color: $text-color-regular;
        transition: background-color .2s;
        &:focus {
            color: $anchor-color;
            outline: none;
        }
        .icon-close {
            font-size: (10/$em) * 1em;
        }
    }
    .sb-list-row .sb-list-menu {
        width: 0;
        flex-shrink: 0;
        opacity: 0;
        top: $baseline / 6;
        height: $baseline * 2;
        padding-right: $baseline / 2;
    }
    .sb-list-row:focus-within .sb-list-menu {
        opacity: 1;
        transition: opacity .25s .25s,
                    background-color .2s;
    }
    .sb-list-row:hover .sb-list-menu,
    .sb-list-active > .sb-list-row .sb-list-menu {
        opacity: 1;
        transition: opacity .25s .25s,
                    background-color .2s;
    }
    @include breakpoint(medium down) {
        .sb-list-row {
            .sb-list-menu {
                @include sb-node-list-menu-compact;
                &:not(.expanded) .sb-list-menu-button {
                    transform: scale(0);
                    pointer-events: none;
                }
                &.expanded {
                    .sb-list-menu-expand {
                        background-color: $gray-100;
                    }
                }
            }
        }
    }

    .sb-list-menu-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-shrink: 0;    
        height: auto;
        padding: 0 $baseline / 2;
        // border-radius: $baseline;
        margin-top: $baseline / 6;
        line-height: $baseline * 1.5;
        font-size: (12/$em) * 1rem;
        font-weight: $semibold;
        color: $anchor-color;
        background-color: $white;
        white-space: nowrap;
        transition: transform .3s;
        &:hover {
            color: $anchor-color-hover;
        }
        .icon {
            flex-shrink: 0;
        }
        @include breakpoint(smallplus down) {
            @include sb-node-list-menu-button-compact;
        }
    }

//Hide/show buttons where applicable
    .sb-list-panel {
        .sb-undelete-page,
        .sb-empty-trash {
            display: none;
        }
    }
    .sb-list-panel.trash {
        .sb-undelete-page {
            display: flex;
        }
        .sb-add-page,
        .sb-delete-page,
        .sb-view-page {
            display: none;
        }
    }
    .sb-list-panel.trash.has-children {
        .sb-empty-trash {
            display: flex;
        }
    }
    .compact .sb-list-panel {
        .sb-edit-page,
        .sb-view-page {
            display: none;
        }
    }
    .sb-list-panel.has-children {
        .sb-list-panel-footer .sb-add-page {
            display: none;
        }
    }


//List item expando
    .sb-list-panel .sb-expand {
        z-index: 1;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        left: $baseline;
        top: $baseline * 1.5;
        cursor: pointer;
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        transition: opacity .15s ease;
    }
    svg.expando {
        rect {
            fill: $text-color-light;
        }
        .expando-progress {
            fill: none;
            stroke: $secondary-color;
            stroke-width: 45;
            stroke-dasharray: 900;
            stroke-dashoffset: 900;
        }
        .expando-vertical {
            // opacity: 0;
            transform: scaleY(1);
            transform-origin: center;
            transition: transform .15s ease;
        }
    }
    .expanded > .sb-expand svg.expando {
        .expando-vertical {
            transform: scaleY(0);
        }
    }

    .sb-expand:hover svg.expando,
    .is-active > .sb-expand svg.expando {
        rect {
            fill: $text-color-regular;
        }
    }
    .has-children > .sb-expand {
        // display: block;
        opacity: 1;
        pointer-events: auto;
    }

//lines
    .sb-list-children::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: $baseline;
        width: 1px;
        background-color: $border-color;
    }
    .sb-list-row {
        border-bottom: 1px solid $border-color;
    }
    //end node
    .sb-list-row::after {
        content: '';
        flex-shrink: 0;
        z-index: 10;
        display: block;
        position: relative;
        top: 6px;
        margin-right: -6px;
        width: 11px;
        height: 11px;
        background-color: $border-color;
        border: 5px solid transparent;
        background-clip: padding-box;
        border-radius: 100%;
    }
    //mask to cover left line for expanded last children
    .sb-list-children .sb-list-item:last-of-type::after {
        content: '';
        display: block;
        position: absolute;
        top: $baseline * 3;
        left: 0;
        bottom: 0;
        width: 1px;
        pointer-events: none;
        background-color: $white;
    }
    //detached item visual doodad
    .trash .sb-list-base > .sb-list-item > .sb-list-row {
        &::before {
            content: '//';
            display: block;
            position: absolute;
            left: 1px;
            top: calc(#{$baseline * 1.5} - 1px);
            width: 1rem;
            text-align: right;
            height: $baseline;
            font-size: $baseline;
            line-height: 1;
            color: $border-color;
            background-color: $white;
        }
        &:hover::before,
        &.is-active::before {
            color: $text-color-regular;
        }
    }
    //color the line and node
    .sb-list:not(.sb-list-dragging) {
        .sb-list-row:hover,
        .sb-expand:hover + .sb-list-row,
        .is-current > .sb-list-row {
            border-color: $text-color-regular;
            &::after {
                background-color: $text-color-regular;
            }
        }
        //slightly enbiggen the node on hover
        .sb-list-item:not(.is-current) > .sb-list-row:hover::after {
            border-width: 2px;
        }
    }
    //sb-list-active applies when a context menu is open
    .sb-list-active > .sb-list-row {
        border-color: $text-color-regular;
    }

    //fully enbiggen the node and bold the line on active
    .is-current > .sb-list-row{
        &::after {
            border-width: 0;
        }
    }

//dragon drop
    .sb-list-item-dragging {
        box-shadow: 0 4px 4px -2px rgba(0,0,0,0.3);
        & > .sb-list-row {
            border-color: $secondary-color;
            &::after {
                border-width: 0;
                background-color: $secondary-color;
            }
        }
    }
    //hide all buttons, disable expandos
    .sb-list-dragging {
        .sb-expand {
            pointer-events: none;
        }
        .sb-list-menu {
            pointer-events: none;
            visibility: hidden;
        }
    }
    //drop zone for appending to end of list
    .sb-list-base,
    .sb-list-children {
        padding-bottom: $baseline;
    }
    //indicate that item is about to be dropped inside
    .sb-list-drop-inside:not(.expanded) {
        & > .sb-list-row {
            background-color: rgba(0,0,0,0.05);
        }
        & > .sb-expand {
            opacity: 1;
            .expando-progress {
                transition: stroke-dashoffset 1s;
                stroke-dashoffset: 0;
            }
        }
    }
    //fix drag api bubbling quirks (maybe?)
    .sb-list-dragging .sb-list-item:not(.sb-list-item-dragging) > .sb-list-row {
        .sb-list-path,
        .sb-list-link {
            pointer-events: none;
        }
    }

//compact sidebar list
@keyframes sb-list-show-compact-menu-buttons {
    from {
        transform: scale(0);
    }
}
.compact {
    .sb-list-panel {
        padding-right: $baseline;
    }
    .sb-list-item.dragging {
        opacity: .2;
    }
    .sb-list-row {
        background-size: (10/$em) * 1rem (10/$em) * 1rem;
        background-position:  $baseline * 2/3 $baseline / 2;
        background-repeat: no-repeat;
        input {
            position: absolute;
            left: 0;
            bottom: calc(#{$baseline / -4} + 1px);
            width: 100%;
            height: calc(#{$baseline * 2} - 1px);
            padding: 0px $baseline * 2 calc(#{$baseline / 2} - 3px);
            background-clip: content-box;
            border: none;
            border-bottom: 2px solid $anchor-color;
            border-radius: 0;
            box-shadow: none;
            line-height: $baseline * 1.5;
            &:focus {
                box-shadow: 0 8px 6px -7px rgba(0,0,0,0.3);
                border: none;
                border-bottom: 2px solid $anchor-color;
            }
        }
        &::after {
            // display: none;
            top: 4px;
            margin-right: -2px;
            width: 7px;
            height: 7px;
            border-width: 3px;
        }
    }
    .is-current > .sb-list-row::after {
        border-width: 0;
    }
    .sb-list-drag, 
    .sb-list-link, 
    .sb-list-path {
        transform: translateY($baseline / 4);
        &::after {
            bottom: (2/$em) * 1rem;
        }
    }
    .sb-list-row,
    .sb-list-base > .sb-list-item > .sb-list-row {
        padding-left: 0;
    }
    .sb-list-link {
        padding-left: 2 * $baseline;
    }
    .sb-list-base > .sb-list-item > .sb-list-children,
    .sb-list-children {
        padding-left: $baseline;
    }
    .has-children > .sb-list-row {
        @include sb-icon-arrow($text-color-medium);
    }
    .has-children.is-active > .sb-list-row {
        @include sb-icon-arrow-down($text-color-medium);

    }

    .sb-list-row .sb-list-menu {
        @include sb-node-list-menu-compact;
        .sb-list-menu-button ~ .sb-list-menu-expand {
            background-color: $gray-100;
        }
        &:not(.expanded) .sb-list-menu-button {
            pointer-events: auto;
            transform: none;
        }
    }
    .sb-list-menu-button {
        @include sb-node-list-menu-button-compact;
        width: $baseline * 2;
        height: $baseline * 2;
        transition: none;
        animation: sb-list-show-compact-menu-buttons .2s both;
        &:nth-last-child(3) {
            animation-delay: .05s;
        }
        &:nth-last-child(4) {
            animation-delay: .1s;
        }
    }
}
