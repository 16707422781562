.sb-has-toolbar .site {
    @include breakout(get-toolbar-height()) {
        margin-bottom: $value;
    }
}

.sb-toolbar-cont {
    z-index: 100;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @include breakpoint(large up) {
        bottom: $baseline;
    }
    .sb-admin-tools-sidebar & {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        height: auto;
    }
}

#sb-toolbar {
    @include sb-toolbar;
}

